import { BalButton, BalDivider, BalHeading } from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import classNames from "classnames";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { ReactElement, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import {
    MOBILE_MENU_OUTER_CONTAINER_ID,
    MOBILE_MENU_PAGEWRAP_ID,
} from "./ProductPageLayout.const";
import * as style from "./ProductPageLayout.module.scss";
import { ProductPageLayoutProps } from "./ProductPageLayout.types";

import {
    LanguageSwitch,
    NavBar,
    PageContainer,
    ProductContactModal,
    SEO,
} from "@modules/shared/components";
import { ModalName } from "@modules/shared/enums";
import {
    activeMenuState,
    FordMenuItem,
    modalState,
    selectMenu,
} from "@modules/shared/state";

// markup
const ProductPageLayout: React.FC<ProductPageLayoutProps> = ({
    title,
    page,
    language,
    children,
}) => {
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const activeMenu = useRecoilValue(activeMenuState);
    const dataLoadable = useRecoilValueLoadable(selectMenu);
    const isDesktopDown = useMediaQuery("(max-width: 1124px)");
    const isDesktop = useMediaQuery("(min-width: 1124px)");
    const [shownModal, setModal] = useRecoilState(modalState);

    useEffect(() => {
        setMenuOpen(false);
    }, [isDesktop]);

    const toggleMenu = (): void => {
        setMenuOpen(!isMenuOpen);
    };

    const handleLanguageChange = () => {
        setMenuOpen(false);
    };

    const renderContactButton = (isMobile?: boolean): ReactElement => (
        <BalButton
            onClick={() => {
                setModal(ModalName.ProductContact);
                if (!isDesktop) {
                    setMenuOpen(false);
                }
            }}
            rounded
            color={isMobile ? "primary" : "primary-light"}
            className={classNames({ [style.mobileButton]: isMobile })}
        >
            {t("product-page.button.contact")}
        </BalButton>
    );

    const renderMobileMenu = (data: FordMenuItem[]): ReactElement => (
        <Menu
            isOpen={isMenuOpen}
            customBurgerIcon={false}
            customCrossIcon={false}
            pageWrapId={MOBILE_MENU_PAGEWRAP_ID}
            outerContainerId={MOBILE_MENU_OUTER_CONTAINER_ID}
        >
            <div
                className={classNames(
                    "flex flex-direction-column justify-content-space-between",
                    style.mobileMenuWrapper,
                )}
            >
                <ul
                    className={classNames(
                        style.navigationListMobile,
                        style.navigationList,
                    )}
                >
                    {data.map((menuItem, index) => (
                        <li
                            key={`mobile-menu-item-${index}`}
                            className={style.navigationListItem}
                        >
                            <BalHeading
                                level="p"
                                visualLevel="h5"
                                color="primary"
                            >
                                <AnchorLink
                                    to={`/${language}${menuItem.link.toLowerCase()}`}
                                    onAnchorLinkClick={() => setMenuOpen(false)}
                                    stripHash
                                    className={classNames(
                                        style.navigationListItemLink,
                                        {
                                            [style.navigationListItemLinkActive]:
                                                activeMenu ===
                                                menuItem.link.toLowerCase(),
                                        },
                                    )}
                                >
                                    {menuItem.text}
                                </AnchorLink>
                            </BalHeading>
                        </li>
                    ))}
                </ul>
                {renderContactButton(true)}
            </div>
        </Menu>
    );

    const renderMobileMenuToggle = (): ReactElement => (
        <div className="flex align-items-center ml-small">
            <BalDivider layout="vertical" className="mr-small"></BalDivider>

            <BalButton
                className={classNames("ml-xx-small", style.mobileMenuTrigger)}
                iconRight={isMenuOpen ? "close" : "menu-bars"}
                color="text"
                flat
                onClick={toggleMenu}
            >
                {isMenuOpen
                    ? t("all.mobile.menu.close.title")
                    : t("all.mobile.menu.open.title")}
            </BalButton>
        </div>
    );

    const renderDesktopMenu = (data: FordMenuItem[]): ReactElement => (
        <ul
            className={classNames(
                style.navigationListDesktop,
                style.navigationList,
            )}
        >
            {data.map((menuItem, index) => (
                <li
                    key={`mobile-menu-item-${index}`}
                    className={style.navigationListItem}
                >
                    <BalHeading level="p" visualLevel="h5" color="primary">
                        <AnchorLink
                            to={`/${language}${menuItem.link.toLowerCase()}`}
                            onAnchorLinkClick={() => setMenuOpen(false)}
                            stripHash
                            className={classNames(
                                style.navigationListItemLink,
                                {
                                    [style.navigationListItemLinkActive]:
                                        activeMenu ===
                                        menuItem.link.toLowerCase(),
                                },
                            )}
                        >
                            {menuItem.text}
                        </AnchorLink>
                    </BalHeading>
                </li>
            ))}
        </ul>
    );

    const renderNavBar = (data: FordMenuItem[]): ReactElement => (
        <NavBar
            left={<>{!isDesktopDown && renderDesktopMenu(data)}</>}
            right={
                <div className="flex gap-small">
                    <LanguageSwitch
                        page={page}
                        language={language}
                        onChange={handleLanguageChange}
                        prefix={process.env.GATSBY_PATH_PREFIX_FORD}
                    />

                    {isDesktopDown && renderMobileMenuToggle()}
                    {!isDesktopDown && renderContactButton()}
                </div>
            }
        ></NavBar>
    );

    const renderLoadable = (): ReactElement | null => {
        switch (dataLoadable.state) {
            case "hasValue":
                return (
                    <>
                        {renderNavBar(dataLoadable.contents)}
                        {renderMobileMenu(dataLoadable.contents)}
                        {shownModal === ModalName.ProductContact && (
                            <ProductContactModal
                                onClose={() => setModal(undefined)}
                            />
                        )}
                    </>
                );
            case "loading":
            case "hasError":
                return null;
        }
    };

    return (
        <div className={style.lProductPage}>
            <SEO title={title} language={language} />
            <PageContainer
                id={MOBILE_MENU_OUTER_CONTAINER_ID}
                backgroundColor="white"
            >
                {renderLoadable()}
                <div
                    className={style.productPageContent}
                    id={MOBILE_MENU_PAGEWRAP_ID}
                >
                    {children}
                </div>
            </PageContainer>
        </div>
    );
};

export default ProductPageLayout;
