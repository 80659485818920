import { BalButton, BalHeading } from "@baloise/ds-react";
import classNames from "classnames";
import { navigate } from "gatsby";
import React from "react";
import { useRecoilValue } from "recoil";

import * as style from "./SimulateBounty.module.scss";
import { SimulateBountyProps } from "./SimulateBounty.types";

import { ImageText } from "@modules/shared/components";
import { selectSimulateBounty } from "@modules/shared/state";

const SimulateBounty: React.FC<SimulateBountyProps> = ({ className }) => {
    const data = useRecoilValue(selectSimulateBounty);

    return (
        <ImageText {...data} className={className}>
            <div className={classNames(style.cSimulateBounty, "px-xx-large")}>
                <BalHeading level="h2">{data.title}</BalHeading>
                <BalButton onClick={() => navigate(data.buttonUrl)} rounded>
                    {data.buttonText}
                </BalButton>
            </div>
        </ImageText>
    );
};

export default SimulateBounty;
