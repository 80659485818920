import { BalHeading } from "@baloise/ds-react";
import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import * as style from "./Advisories.module.scss";
import { AdvisoriesProps } from "./Advisories.types";

import { AccordionList } from "@modules/shared/components";
import { selectAdvisories, selectMenu } from "@modules/shared/state";

const Advisories: React.FC<AdvisoriesProps> = ({ className }) => {
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const data = useRecoilValue(selectAdvisories);
    const menuItems = useRecoilValue(selectMenu);
    const { t } = useTranslation();
    const anchorId =
        t("product-page.anchor.advisories") ?? "product-page-advisories";
    const { ref } = useAnchorMenu(menuItems);

    const renderMobile = () => (
        <AccordionList
            className="desktop:hidden tablet:hidden w-full"
            data={data.map((qa) => ({
                label: qa.question,
                content: qa.answer,
            }))}
        ></AccordionList>
    );

    const renderDesktop = () => (
        <div className="mobile:hidden">
            <div className="grid is-gapless">
                <div className="col">
                    <div className="h-full">
                        <div className="flex flex-direction-column">
                            {data.map((qa, index) => (
                                <button
                                    key={index}
                                    className={classnames(
                                        style.cAdvisories__menuItem,
                                        {
                                            [style.cAdvisories__menuItemSelected]:
                                                index === questionIndex,
                                        },
                                    )}
                                    onClick={() => setQuestionIndex(index)}
                                >
                                    {qa.question}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col is-two-thirds">
                    <div
                        className={classnames(
                            "h-full",
                            style.cAdvisories__question,
                        )}
                    >
                        <BalHeading
                            level="h1"
                            visualLevel="h3"
                            className="mb-large"
                        >
                            {data[questionIndex || 0].question}
                        </BalHeading>
                        <p
                            className={style.cAdvisories__answer}
                            dangerouslySetInnerHTML={{
                                __html: data[questionIndex || 0].answer,
                            }}
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    );

    return Array.isArray(data) && data.length > 0 ? (
        <section
            ref={ref}
            id={anchorId}
            className={classnames(className, "container")}
        >
            {renderMobile()}
            {renderDesktop()}
        </section>
    ) : null;
};
export default Advisories;
