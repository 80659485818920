import { BalContent, BalHeading, BalStack, BalText } from "@baloise/ds-react";
import classNames from "classnames";
import { indexOf, last } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import * as style from "./UpsInfo.module.scss";
import { UpsInfoProps, UspTitleTextProps } from "./UspInfo.types";

import { FordUspItem, selectMenu, selectUSP } from "@modules/shared/state";

const IconText: React.FC<UspTitleTextProps> = ({ title, text, icon }) => {
    return (
        <BalStack layout="horizontal" align="top start">
            <img src={icon} alt="icon" className="mr-x-small" />
            <BalContent>
                <BalText size="block" color="primary">
                    {title}
                </BalText>
                <BalText size="small">
                    <span
                        dangerouslySetInnerHTML={{ __html: text }}
                        className="text-grey-dark"
                    ></span>
                </BalText>
            </BalContent>
        </BalStack>
    );
};

const UspInfo: React.FC<UpsInfoProps> = ({ className }) => {
    const data = useRecoilValue(selectUSP);
    const menuItems = useRecoilValue(selectMenu);
    const { t } = useTranslation();
    const anchorId = t("product-page.anchor.usp") ?? "product-page-usp";
    const { ref } = useAnchorMenu(menuItems);

    return (
        <section ref={ref} className={className} id={anchorId}>
            <BalStack
                layout="vertical"
                space="x-large"
                className={classNames("container", style.wrapper)}
            >
                <BalHeading level="h3">{data.title}</BalHeading>

                <div className="grid is-multiline">
                    {data.usps
                        .reduce((acc, usp) => {
                            const lastColumn = last(acc) ?? [];
                            const lastColumnIndex = indexOf(lastColumn, acc);

                            if (acc.length === 0 || lastColumn?.length === 2) {
                                acc.push([usp]);
                                return acc;
                            }

                            return acc.map((columnUsp, i) => {
                                if (i === lastColumnIndex) {
                                    return [...lastColumn, usp];
                                }
                                return columnUsp;
                            });
                        }, [] as FordUspItem[][])
                        .map((uspPair, index) => (
                            <div
                                key={`usp-${index}`}
                                className={classNames(
                                    "col",
                                    "is-full",
                                    style.mobilePbXLarge,
                                )}
                            >
                                <div className="grid">
                                    {uspPair.map((pair, pairIndex) => (
                                        <div
                                            key={`usp-pair-${pairIndex}`}
                                            className="col is-half"
                                        >
                                            <IconText
                                                title={pair.title}
                                                text={pair.description}
                                                icon={`${process.env.GATSBY_CMS_ASSETS_URL}${pair.link}`}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </BalStack>
        </section>
    );
};
export default UspInfo;
