import { graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
    AdditionalInsurances,
    Advisories,
    Contact,
    Documents,
    Faq,
    Footer,
    Hero,
    InsuranceTypesInfo,
    Legal,
    ProductPageLayout,
    RoadTo,
    SimulateBounty,
    UspInfo,
} from "@modules/productPage";
import { PageProps } from "@modules/shared/types";

const IndexPage: React.FC<PageProps> = ({ pageContext, location }) => {
    const { foundPage: page, language } = pageContext;

    const { t } = useTranslation();
    const documentsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            const hash = location.hash.replace("#", "");
            const translatedDocumentsAnchor = t(
                "product-page.anchor.documents",
            );
            if (hash === translatedDocumentsAnchor) {
                documentsRef?.current?.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                });
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash]);

    return (
        <>
            <ProductPageLayout
                title="Ford Insurance"
                language={language}
                page={page}
            >
                <>
                    <Hero className="mb-xx-large" />

                    <UspInfo className="mb-xx-large" />

                    <InsuranceTypesInfo className="mb-xx-large" />

                    <Advisories className="mb-xx-large" />

                    <SimulateBounty className="mb-xx-large" />

                    <AdditionalInsurances className="mb-xx-large" />

                    <RoadTo className="mb-xx-large" />

                    <Contact className="mb-xx-large" />

                    <Faq className="mb-xx-large" />

                    <div ref={documentsRef}>
                        <Documents className="mb-xx-large" />
                    </div>

                    <Legal className="mb-xx-large" />

                    <Footer />
                </>
            </ProductPageLayout>
        </>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
