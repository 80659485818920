import { BalContent, BalHeading, BalText } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";

import * as style from "./Contact.module.scss";
import { ContactProps } from "./Contact.types";

import { ImageText } from "@modules/shared/components";
import { selectContact } from "@modules/shared/state";

const Contact: React.FC<ContactProps> = ({ className }) => {
    const data = useRecoilValue(selectContact);
    return (
        <ImageText {...data} className={className} reverseFlex>
            <div className="px-large">
                <BalHeading level="h2">{data.title}</BalHeading>
                <BalContent>
                    <BalText>
                        <span
                            className={classNames(
                                "text-grey-dark",
                                style.cContact__description,
                            )}
                            dangerouslySetInnerHTML={{
                                __html: data.description,
                            }}
                        ></span>
                    </BalText>
                </BalContent>
            </div>
        </ImageText>
    );
};

export default Contact;
