import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";

import * as style from "./Footer.module.scss";
import { FooterProps } from "./Footer.types";

import { selectFooter } from "@modules/shared/state";

const Footer: React.FC<FooterProps> = ({ className }) => {
    const data = useRecoilValue(selectFooter);

    return (
        <section className={classNames(className, style.cFooter)}>
            <div className="container">
                <ul className="flex align-items-center p-large">
                    {data.map((fItem, index) => (
                        <li key={`footer-item-${index}`} className="mr-large">
                            <a
                                href={fItem.urlLink}
                                className={classNames(
                                    "link is-inverted",
                                    style.cFooter__link,
                                )}
                            >
                                {fItem.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Footer;
