import { BalContent, BalHeading, BalText } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import * as style from "./Legal.module.scss";
import { LegalProps, LegalSectionProps } from "./Legal.types";

import { selectLegal, selectMenu } from "@modules/shared/state";

const LegalSection: React.FC<LegalSectionProps> = ({ item, name }) => {
    const menuItems = useRecoilValue(selectMenu);
    const { t } = useTranslation();
    const { ref } = useAnchorMenu(menuItems);

    return (
        <div
            className="grid is-multiline"
            ref={ref}
            id={t(item.anchor) ?? `legal-${name}`}
        >
            <div className="col mobile:is-full desktop:is-full is-half-widescreen">
                <BalContent>
                    <BalHeading level="h3">{item.title}</BalHeading>
                    {item.subTitle && (
                        <BalText>
                            <span
                                className={style.cLegal__description}
                                dangerouslySetInnerHTML={{
                                    __html: item.subTitle,
                                }}
                            ></span>
                        </BalText>
                    )}
                </BalContent>
            </div>
            <div className="col mobile:is-full desktop:is-full is-half-widescreen">
                <BalText>
                    <span
                        className={classNames(
                            style.cLegal__body,
                            "text-grey-dark",
                        )}
                        dangerouslySetInnerHTML={{
                            __html: item.description,
                        }}
                    ></span>
                </BalText>
            </div>
        </div>
    );
};

const Legal: React.FC<LegalProps> = ({ className }) => {
    const data = useRecoilValue(selectLegal);

    return (
        <section className={classNames(className, "container")}>
            <div>
                {data.map((item, index) => (
                    <LegalSection item={item} key={index} name={`${index}`} />
                ))}
            </div>
        </section>
    );
};

export default Legal;
