import { BalContent, BalHeading, BalStack } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import { FaqProps } from "./Faq.types";

import { AccordionList } from "@modules/shared/components";
import { Language } from "@modules/shared/enums";
import {
    currentLanguageState,
    selectFaq,
    selectMenu,
} from "@modules/shared/state";

export const Faq: React.FC<FaqProps> = ({ className }) => {
    const data = useRecoilValue(selectFaq);
    const language = useRecoilValue(currentLanguageState);
    const menuItems = useRecoilValue(selectMenu);
    const { t } = useTranslation();
    const anchorId = t("product-page.anchor.faq") ?? "product-page-faq";
    const { ref } = useAnchorMenu(menuItems);

    return (
        <section ref={ref} id={anchorId} className={classNames(className)}>
            <BalStack
                layout="vertical"
                align="center"
                space="x-large"
                className="container"
            >
                <BalHeading level="h2" subtitle>
                    {data.title}
                </BalHeading>

                <BalContent align="start">
                    {data.faqCategories.map((faqCategory, index) => (
                        <div className="w-full" key={`faq-category-${index}`}>
                            <BalStack layout="vertical">
                                <BalHeading className="w-full" level="h3">
                                    {language === Language.NL
                                        ? faqCategory.title
                                        : faqCategory.titleFr}
                                </BalHeading>

                                <AccordionList
                                    key={`faq-category-item-${index}`}
                                    className="w-full mb-medium"
                                    data={faqCategory.items.map((qa) => ({
                                        label: qa.question,
                                        content: qa.answer,
                                    }))}
                                ></AccordionList>
                            </BalStack>
                        </div>
                    ))}
                </BalContent>
            </BalStack>
        </section>
    );
};

export default Faq;
