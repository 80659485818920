import { useInView } from "react-intersection-observer";
import { useSetRecoilState } from "recoil";

import { activeMenuState, FordMenuItem } from "@modules/shared/state";

export const useAnchorMenu = (menuItems: FordMenuItem[] = []) => {
    const setActiveMenu = useSetRecoilState(activeMenuState);

    const { ref, inView } = useInView({
        /**
         * Create a horizontal line vertically centered
         * that will help trigger an intersection at that the very point
         */
        rootMargin: "-50% 0% -50% 0%",
        onChange(inView, entry) {
            const anchorId = `#${entry.target.id}`;

            // only allow anchor links defined in the cms
            const targetMenuItem = menuItems.find(
                (menuItem) => menuItem.link.toLowerCase() === anchorId,
            );

            if (inView && targetMenuItem) {
                setActiveMenu(anchorId);
            }
        },
    });

    return { ref, inView };
};
