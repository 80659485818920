// extracted by mini-css-extract-plugin
export var lProductPage = "ProductPageLayout-module--l-product-page--rDJHQ";
export var mobileButton = "ProductPageLayout-module--mobile-button--JDxz4";
export var mobileMenuTrigger = "ProductPageLayout-module--mobile-menu-trigger--17jH7";
export var mobileMenuWrapper = "ProductPageLayout-module--mobile-menu-wrapper--ZWWce";
export var navBar = "ProductPageLayout-module--nav-bar--p7eYV";
export var navigationList = "ProductPageLayout-module--navigation-list--VeXm1";
export var navigationListDesktop = "ProductPageLayout-module--navigation-list--desktop--eDUBk";
export var navigationListItem = "ProductPageLayout-module--navigation-list-item--L-NNZ";
export var navigationListItemLink = "ProductPageLayout-module--navigation-list-item-link--LwhTR";
export var navigationListItemLinkActive = "ProductPageLayout-module--navigation-list-item-link--active--qsDz7";
export var navigationListMobile = "ProductPageLayout-module--navigation-list--mobile--tOblY";
export var productPageContent = "ProductPageLayout-module--product-page-content--XGEmG";