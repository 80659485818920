import { BalContent, BalStack, BalText } from "@baloise/ds-react";
import { TableColumn, TableData } from "@lg-cfa/components";
import classnames from "classnames";
import React from "react";

import { ColumnKeys } from "./InsuranceTypes.enums";
import * as style from "./InsuranceTypesInfo.module.scss";
import {
    ColumnsHeaderData,
    InsuranceTypesInfoData,
    TableHeaderItemProps,
} from "./InsuranceTypesInfo.types";

import Check from "@images/Ford-product-page/check.svg";

const TableHeaderItem: React.FC<TableHeaderItemProps> = ({ title, text }) => {
    return (
        <BalStack layout="vertical" align="center">
            <BalContent align="center">
                <BalText size="block">{title}</BalText>
                <BalText
                    size="small"
                    className={classnames(
                        style.hasTextGreyLight,
                        "text-weight-light",
                    )}
                >
                    {text}
                </BalText>
            </BalContent>
        </BalStack>
    );
};

const CheckboxCell: React.FC<{ checked: boolean }> = ({ checked }) =>
    checked ? (
        <div className="flex align-items-center justify-content-center">
            <img src={Check} alt="icon" className="mr-x-small" />
        </div>
    ) : null;

export const createColumnHeaderData = (
    data: InsuranceTypesInfoData,
): ColumnsHeaderData => ({
    [ColumnKeys.ProductOne]: {
        title: data.productLabel1,
        subTitle: data.productSubLabel1,
    },
    [ColumnKeys.ProductTwo]: {
        title: data.productLabel2,
        subTitle: data.productSubLabel2,
    },
    [ColumnKeys.ProductTree]: {
        title: data.productLabel3,
        subTitle: data.productSubLabel3,
    },
});

export const createTableData = (data: InsuranceTypesInfoData): TableData => [
    {
        label: data.productCategoryLabel1,
        productOne: true,
        productTwo: true,
        productTree: true,
    },
    {
        label: data.productCategoryLabel2,
        productOne: false,
        productTwo: true,
        productTree: true,
    },
    {
        label: data.productCategoryLabel3,
        productOne: false,
        productTwo: true,
        productTree: true,
    },
    {
        label: data.productCategoryLabel4,
        productOne: false,
        productTwo: true,
        productTree: true,
    },
    {
        label: data.productCategoryLabel5,
        productOne: false,
        productTwo: true,
        productTree: true,
    },
    {
        label: data.productCategoryLabel6,
        productOne: false,
        productTwo: true,
        productTree: true,
    },
    {
        label: data.productCategoryLabel7,
        productOne: false,
        productTwo: false,
        productTree: true,
    },
];

export const createColumns = (
    headerData: Record<
        Exclude<ColumnKeys, ColumnKeys.Label>,
        {
            title: string;
            subTitle: string;
        }
    >,
): TableColumn<ColumnKeys>[] => [
    {
        key: ColumnKeys.Label,
        hideLabel: true,
    },
    {
        key: ColumnKeys.ProductOne,
        headerComponent: () => (
            <TableHeaderItem
                title={headerData[ColumnKeys.ProductOne].title}
                text={headerData[ColumnKeys.ProductOne].subTitle}
            />
        ),
        component: (checked) => <CheckboxCell checked={checked as boolean} />,
    },
    {
        key: ColumnKeys.ProductTwo,
        headerComponent: () => (
            <TableHeaderItem
                title={headerData[ColumnKeys.ProductTwo].title}
                text={headerData[ColumnKeys.ProductTwo].subTitle}
            />
        ),
        component: (checked) => <CheckboxCell checked={checked as boolean} />,
    },
    {
        key: ColumnKeys.ProductTree,
        headerComponent: () => (
            <TableHeaderItem
                title={headerData[ColumnKeys.ProductTree].title}
                text={headerData[ColumnKeys.ProductTree].subTitle}
            />
        ),
        component: (checked) => <CheckboxCell checked={checked as boolean} />,
    },
];
