import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalStack,
    BalText,
} from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import * as style from "./AdditionalInsurances.module.scss";
import { AdditionalInsurancesProps } from "./AdditionalInsurances.types";

import { selectAdditionalInsurances, selectMenu } from "@modules/shared/state";

const AdditionalInsurances: React.FC<AdditionalInsurancesProps> = ({
    className,
}) => {
    const data = useRecoilValue(selectAdditionalInsurances);
    const menuItems = useRecoilValue(selectMenu);
    const { t } = useTranslation();
    const anchorId =
        t("product-page.anchor.additional-insurances") ??
        "product-page-insurance-additional-insurances";
    const { ref } = useAnchorMenu(menuItems);

    return (
        <section ref={ref} id={anchorId} className={className}>
            <BalStack
                layout="vertical"
                space="x-large"
                className="container is-desktop mb-x-large"
                align="center"
            >
                <div className="text-center">
                    <BalHeading level="h2" subtitle>
                        {data.title}
                    </BalHeading>
                    <BalText className={style.cAdditionalInsurances__subtitle}>
                        {data.subTitle}
                    </BalText>
                </div>
                <div className={style.cAdditionalInsurances__grid}>
                    {data.services.map((service, index) => (
                        <BalCard
                            flat
                            border
                            key={`additional-insurances-card-${index}`}
                        >
                            <BalCardContent className="h-full">
                                <div className="flex flex-direction-column justify-content-space-between h-full">
                                    <div className="mb-medium">
                                        <BalHeading
                                            level="h3"
                                            color="primary"
                                            space="none"
                                            className="mb-medium"
                                        >
                                            {service.productTitle}
                                        </BalHeading>

                                        <div
                                            className={
                                                style.cAdditionalInsurances__list
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: service.productHighlights,
                                            }}
                                        />
                                    </div>
                                    <div className="flex align-items-center gap-small">
                                        <BalButton
                                            rounded
                                            color="info"
                                            href={`${process.env.GATSBY_CMS_ASSETS_URL}/${service.productCallToActionLink}`}
                                            target="_blank"
                                            className={
                                                style.cAdditionalInsurances__button
                                            }
                                        >
                                            {service.productCallToAction}
                                        </BalButton>

                                        {service.productMoreInfoLink && (
                                            <a
                                                href={`${process.env.GATSBY_CMS_ASSETS_URL}/${service.productMoreInfoLink}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                {service.productMoreInfo
                                                    ? service.productMoreInfo
                                                    : t(
                                                          "product-page.extra-services.info-fiche.link.text",
                                                      )}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </BalCardContent>
                        </BalCard>
                    ))}
                </div>
            </BalStack>
            <div>
                <img
                    className={classNames(
                        style.cAdditionalInsurances__image,
                        "bg-light-blue",
                    )}
                    src={`${process.env.GATSBY_CMS_ASSETS_URL}${data.image}`}
                />
            </div>
        </section>
    );
};

export default AdditionalInsurances;
