import {
    BalButton,
    BalCard,
    BalCardActions,
    BalCardContent,
    BalHeading,
    BalStage,
    BalStageBody,
    BalStageImage,
    BalText,
} from "@baloise/ds-react";
import classNames from "classnames";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";

import * as style from "./Hero.module.scss";
import { HeroProps } from "./Hero.types";

import { ModalName } from "@modules/shared/enums";
import { modalState, selectHero } from "@modules/shared/state";

const Hero: React.FC<HeroProps> = ({ className }) => {
    const data = useRecoilValue(selectHero);
    const setModal = useSetRecoilState(modalState);
    const { t } = useTranslation();

    return (
        <section className={className}>
            <BalStage
                className={classNames(style.cHero__stage, "header-image")}
            >
                <BalStageImage
                    className={classNames(style.cHero__image, "image")}
                    srcSet={`${process.env.GATSBY_CMS_ASSETS_URL}${data.image}`}
                />
                <BalStageBody className={style.cHero__body}>
                    <BalCard
                        fullheight
                        flat
                        className={classNames(style.cHero__card, "p-medium")}
                    >
                        <BalCardContent>
                            <BalHeading color="" level="h1">
                                {data.title}
                            </BalHeading>
                            <BalHeading
                                className={style.cHero__subTitle}
                                level="h2"
                            >
                                {data.subTitle}
                            </BalHeading>
                            <BalText space="none" className="mt-small">
                                {data.description}
                            </BalText>
                        </BalCardContent>
                        <BalCardActions>
                            <BalButton
                                onClick={() => navigate(data.simButtonUrl)}
                                rounded
                            >
                                {data.simButtonText}
                            </BalButton>
                            <BalButton
                                onClick={() =>
                                    setModal(ModalName.ProductContact)
                                }
                                rounded
                                color="primary-light"
                            >
                                {t("product-page.button.contact")}
                            </BalButton>
                        </BalCardActions>
                    </BalCard>
                </BalStageBody>
            </BalStage>
        </section>
    );
};

export default Hero;
