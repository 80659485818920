import {
    BalButton,
    BalContent,
    BalHeading,
    BalStack,
    BalText,
} from "@baloise/ds-react";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useAnchorMenu } from "../../hooks/useAnchorMenu";

import * as style from "./RoadTo.module.scss";
import { RoadToProps } from "./RoadTo.types";

import { selectMenu, selectRoadTo } from "@modules/shared/state";

export const RoadTo: React.FC<RoadToProps> = ({ className }) => {
    const data = useRecoilValue(selectRoadTo);
    const { t } = useTranslation();
    const menuItems = useRecoilValue(selectMenu);
    const anchorId = t("product-page.anchor.roadmap") ?? "product-page-roadmap";
    const { ref } = useAnchorMenu(menuItems);

    return (
        <section ref={ref} className={className} id={anchorId}>
            <BalStack
                layout="vertical"
                space="x-large"
                className="container is-desktop"
                align="center"
            >
                <div className="text-center">
                    <BalHeading level="h2" subtitle>
                        {data.title}
                    </BalHeading>
                    <BalText className={style.cRoadTo__subtitle}>
                        {data.subTitle}
                    </BalText>
                </div>
                <div className="grid">
                    {data.roadTos.map((roadTo, index) => (
                        <div key={`road-to-${index}`} className="col">
                            <BalStack align="top start" layout="vertical">
                                <img
                                    src={`${process.env.GATSBY_CMS_ASSETS_URL}${roadTo.iconLink}`}
                                    alt="icon"
                                    className="mr-x-small"
                                />
                                <BalHeading level="h5">
                                    {roadTo.itemTitle}
                                </BalHeading>
                                <BalContent align="start">
                                    <BalText>
                                        <span
                                            className="text-grey-dark"
                                            dangerouslySetInnerHTML={{
                                                __html: roadTo.itemDescription,
                                            }}
                                        ></span>
                                    </BalText>
                                </BalContent>
                            </BalStack>
                        </div>
                    ))}
                </div>
                <BalButton onClick={() => navigate(data.ctaUrl)} rounded>
                    {data.ctaTitle}
                </BalButton>
            </BalStack>
        </section>
    );
};

export default RoadTo;
